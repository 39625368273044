<template>
  <div class="parser-page">
    <v-card class="parser-card">
      <v-card-title class="parser-title">Запуск парсера 161</v-card-title>
      <v-card-text>
        <BaseActionPanel
          v-if="formattedDates.length"
          label="Выберите дату для парсера 161"
          :options="formattedDates"
          button-label="🚀 Запустить парсер"
          @action="handleParserAction"
          :default-value="formattedDates.length > 1 ? formattedDates[1].value : null"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BaseActionPanel from '@/components/baseNew/BaseActionPanel'

export default {
  name: 'Parser161Page',
  components: {
    BaseActionPanel
  },
  data () {
    return {
      formattedDates: []
    }
  },
  methods: {
    async fetchDates () {
      try {
        const response = await this.axios.get('/parser/dates')
        const dates = response.data?.dates || []

        this.formattedDates = dates.map((date, index) => {
          if (index === 0) {
            return { label: `${date} (сегодня с полуночи)`, value: date }
          }
          if (index === 1) {
            return { label: `${date} (последняя загрузка)`, value: date }
          }
          return { label: date, value: date }
        })
      } catch (error) {
        await this.$store.dispatch('systemMessages/error', 'Не удалось загрузить список дат')
      }
    },
    async handleParserAction (selectedDate) {
      try {
        const response = await this.axios.post('/parser/web/base-161', {
          date: selectedDate
        })
        if (response.data?.success) {
          this.$store.dispatch('systemMessages/success', 'Парсер запущен')
        } else {
          this.$store.dispatch('systemMessages/error', 'Ошибка при запуске')
        }
      } catch (error) {
        await this.$store.dispatch('systemMessages/error', 'Ошибка сервера')
      }
    }
  },
  mounted () {
    this.fetchDates()
  }
}
</script>

<style scoped>
  .parser-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    padding: 16px;
  }
  .parser-card {
    width: 100%;
    max-width: 433px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background-color: #fff;
  }
  .parser-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
    color: #333;
  }
  @media (max-width: 600px) {
    .parser-card {
      max-width: 100%;
      padding: 16px;
      border-radius: 0;
    }
  }
</style>
