<template>
  <div class="base-select-wrapper">
    <label
      :for="selectId"
      class="base-select-label">{{ label }}
    </label>
    <select
      :id="selectId"
      class="base-styled-select"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    label: { type: String, required: true },
    options: { type: Array, required: true },
    value: { type: String, default: null },
    selectId: { type: String, default: 'base-select' }
  },
  watch: {
    options: {
      handler () {
        if (this.value === null && this.options.length > 0) {
          this.$emit('input', this.options[0].value)
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
  .base-select-wrapper {
    width: 100%;
    max-width: 413px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .base-select-label {
    font-size: 14px;
    color: #6b7280;
  }
  .base-styled-select {
    width: 100%;
    padding: 10px 14px;
    font-size: 14px;
    color: #374151;
    background-color: #ffffff;
    border: 2px solid #d1d5db;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s, box-shadow 0.3s;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23737484' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
  }
  .base-styled-select:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
  }
</style>
