<template>
  <div class="action-container">
    <BaseSelect
      :label="label"
      :options="options"
      :select-id="selectId"
      :value="selectedValue"
      @input="selectedValue = $event"
    />
    <BaseButton
      :label="buttonLabel"
      :disabled="!selectedValue"
      @click="handleClick"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/baseNew/BaseButton.vue'
import BaseSelect from '@/components/baseNew/BaseSelect.vue'

export default {
  name: 'BaseActionPanel',
  components: { BaseButton, BaseSelect },
  props: {
    label: { type: String, required: true },
    options: { type: Array, required: true },
    buttonLabel: { type: String, default: 'Submit' },
    selectId: { type: String, default: 'base-select' },
    defaultValue: { type: String, default: null }
  },
  data () {
    return {
      selectedValue: this.defaultValue
    }
  },
  watch: {
    defaultValue (newVal) {
      this.selectedValue = newVal
    }
  },
  methods: {
    handleClick () {
      this.$emit('action', this.selectedValue)
    }
  }
}
</script>

<style scoped>
  .action-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border: 2px dashed #d1d5db;
    border-radius: 12px;
    background-color: #f9fafb;
    transition: border-color 0.3s, background-color 0.3s;
  }
  .action-container:hover {
    border-color: #3b82f6;
    background-color: #eef2ff;
  }
</style>
